import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import TestimonialSlider from "../Elements/TestimonialSlider";
import Writer from "../Elements/writer";
import Counter from"../Elements/counter";
import Clients from "../Elements/ClientSlider";
import Team from "./Team";
import User from "./Users";
import Offers from "./Offers"
import Courasal from "../Elements/Coursal";
import WhyChoosePage from "../Elements/Whypage";
import Chat from "../Elements/chat";
import Header from "./Header";

// Create styled components with padding
const Section = styled.section`
  padding: 0px 0;  // You can adjust the padding values as needed
`;

const HeroSection = styled.div`
  padding: 0 0;
  top:0;
`;

const Home = () => {
  return (
    <>
      <Header />
      <Clients></Clients>
      <Writer />
      <Counter/>
      <Offers />
      <Team />
      <User />
      <WhyChoosePage />
      <TestimonialSlider />
      <Chat />
      

     
    </>
  );
};

export default Home;

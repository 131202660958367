// src/components/Landing.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from "styled-components";
import { AuthProvider } from "../context/context"; // Ensure correct path
import PrivateRoute from "../PrivateRoutes"; // Ensure correct path

// Sections
import TopNavbar from "../Nav/TopNavbar";
import Sidebar from "../Nav/Sidebar";
import Home from "../Sections/Home";
import About from "../Sections/about";
import Contact from "../Sections/Contact";
import Service from "../Sections/Service";
import Footer from "../Sections/Footer";
import Order from "../Sections/order";
import Login from "../Login/login";
import Signup from "../Login/Signup";
import Dashboard from "../Sections/Dashboard/Dashboard";
import Orders from "../Sections/Dashboard/Myorders";
import Profile from "../Sections/Dashboard/Profile"; // Example Dashboard page
import Settings from "../Sections/Dashboard/Setting"; // Example Dashboard page
import ScrollToTop from "./ScrolTop";
import MyScreen from "../Sections/Dashboard/Cashout";
import ConfirmationPage from "../Sections/Dashboard/Confirmation";

const MainContent = styled.div`
  margin-top: 80px; // Adjust this based on your navbar height
  padding: 0 5px; // Optional padding for horizontal spacing
`;

export default function Landing() {
  return (
    <AuthProvider>
      <Router>
        <TopNavbar />
        <Sidebar />
        <ScrollToTop />
        <MainContent>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route path="/order" element={<Order />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/payment" element={<MyScreen />} />
            <Route path="/confirmation" element={<ConfirmationPage/>}/>

            {/* Dashboard Routes (Private) */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            >
              <Route path="orders" element={<Orders />} />
              <Route path="profile" element={<Profile />} />
              <Route path="settings" element={<Settings />} />
              
            </Route>
          </Routes>
        </MainContent>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import Client1 from "../../assets/Logos/client1.jpg";
import Client2 from "../../assets/Logos/client2.jpg";
import Client3 from "../../assets/Logos/client3.jpg";
import Client4 from "../../assets/Logos/client4.jpg";
import Client5 from "../../assets/Logos/client5.jpg";

// Define the color palette
const colors = {
  primary: '#025B3A',
  secondary: '#025B50',
  tertiary: '#02505B',
  darkBlue1: '#023A5B',
  darkBlue2: '#02245B',
  overlay: 'rgba(0, 0, 0, 0.3)',
};

// Styled components for the clients slider section
const ClientsSection = styled.div`
  background: linear-gradient(135deg, ${colors.primary}, ${colors.secondary}, ${colors.tertiary}, ${colors.darkBlue1}, ${colors.darkBlue2});
  padding: 20px 0; /* Increased padding for a more spacious look */
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.overlay}; /* Softer overlay */
  z-index: 1;
`;

const SliderContainer = styled.div`
  position: relative;
  z-index: 2; /* Ensures the slider appears above the overlay */
`;

const ClientImage = styled.img`
  max-height: 70px; /* Slightly larger logo size */
  margin: 0 20px;
  border-radius: 10px; /* Added border radius for rounded corners */
  filter: grayscale(100%);
  transition: all 0.3s ease;
  
  &:hover {
    filter: grayscale(0%);
    transform: scale(1.1);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
  }
`;

// Optional: Styled component for a section title (if needed)
const SectionTitle = styled.h2`
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 20px;
  z-index: 2;
  position: relative;
`;

const Clients = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Show 5 logos at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <ClientsSection>
      <Overlay />
      <SliderContainer>
        {/* Optional: Add a section title */}
        {/* <SectionTitle>Our Clients</SectionTitle> */}
        <Slider {...sliderSettings}>
          <div>
            <ClientImage src={Client1} alt="Client 1" />
          </div>
          <div>
            <ClientImage src={Client2} alt="Client 2" />
          </div>
          <div>
            <ClientImage src={Client3} alt="Client 3" />
          </div>
          <div>
            <ClientImage src={Client4} alt="Client 4" />
          </div>
          <div>
            <ClientImage src={Client5} alt="Client 5" />
          </div>
          {/* Add more client logos as needed */}
        </Slider>
      </SliderContainer>
    </ClientsSection>
  );
};

export default Clients;

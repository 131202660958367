import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/context";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Status from "../Dashboard/Satats"

const DashboardPage = () => {
  const { user } = useContext(AuthContext); // Get logged-in user info
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }

    const fetchOrders = async () => {
      try {
        const response = await fetch(
          `https://server.eliteessayaid.com/get_order.php?userId=${user.id}`
        );
        const data = await response.json();

        if (data.success) {
          setOrders(data.orders);
        } else {
          alert("No orders found.");
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
        alert("Failed to fetch orders. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();

    // Listen for payment success and update status
    const handlePaymentSuccess = async (event) => {
      const { orderId, amount } = event.detail; // Get orderId and amount from event
      try {
        const response = await fetch(
          "https://server.eliteessayaid.com/update_order_status.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ orderId, status: "Paid" }),
          }
        );

        const data = await response.json();
        if (data.success) {
          // Update the order status in the local state
          setOrders((prevOrders) =>
            prevOrders.map((order) =>
              order.id === orderId ? { ...order, status: "Paid" } : order
            )
          );
          alert(`Order ID ${orderId} marked as Paid.`);
        } else {
          alert("Failed to update order status.");
        }
      } catch (error) {
        console.error("Error updating order status:", error);
      }
    };

    window.addEventListener("paymentSuccess", handlePaymentSuccess);
    return () => {
      window.removeEventListener("paymentSuccess", handlePaymentSuccess);
    };
  }, [user, navigate]);

  const handlePaymentRedirect = (orderId, amount) => {
    navigate("/payment", { state: { orderId, amount } });
  };

  const handleCancel = async (orderId) => {
    const confirmed = window.confirm(
      "Are you sure you want to cancel this order?"
    );
    if (confirmed) {
      try {
        const response = await fetch(
          "https://server.eliteessayaid.com/delete_order.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ orderId }),
          }
        );

        const data = await response.json();
        if (data.success) {
          setOrders(orders.filter((order) => order.id !== orderId));
          alert("Order canceled successfully.");
        } else {
          alert("Failed to cancel the order.");
        }
      } catch (error) {
        console.error("Error canceling order:", error);
        alert("Failed to cancel the order. Please try again.");
      }
    }
  };

  const handleUpdate = (orderId) => {
    alert(`Proceeding to update Order ID: ${orderId}`);
    // Redirect or open modal for updating order
  };

  if (loading) {
    return <Loading>Loading...</Loading>;
  }

  return (
    <>
    
  
    <DashboardContainer>
    <Status/>
      <DashboardTitle>My Orders</DashboardTitle>
      {orders.length === 0 ? (
        <NoOrders>No orders to display.</NoOrders>
      ) : (
        <OrdersTable>
          <table>
            <thead>
              <tr>
                <th>Academic Level</th>
                <th>Type of Paper</th>
                <th>Topic</th>
                <th>Price</th>
                <th>Status</th>
                <th>Pay</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id}>
                  <td>{order.academic_level}</td>
                  <td>{order.type_of_paper}</td>
                  <td>{order.topic}</td>
                  <td>${order.total_price}</td>
                  <td>{order.status || "Pending"}</td>
                  <td>
                    <PayButton
                      onClick={() =>
                        handlePaymentRedirect(order.id, order.total_price)
                      }
                      disabled={order.status === "Paid"}
                    >
                      {order.status === "Paid" ? "Paid" : `Pay $${order.total_price}`}
                    </PayButton>
                  </td>
                  <td>
                    <Actions>
                      <ActionIcon onClick={() => handleUpdate(order.id)}>
                        <FaEdit title="Update Order" />
                      </ActionIcon>
                      <ActionIcon
                        onClick={() => handleCancel(order.id)}
                        cancel
                      >
                        <FaTrashAlt title="Cancel Order" />
                      </ActionIcon>
                    </Actions>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </OrdersTable>
      )}
    </DashboardContainer>
    </>
  );
};

export default DashboardPage;

// Styled components (same as before)...


// Styled Components


const DashboardContainer = styled.div`
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 128, 0, 0.2);
`;

const DashboardTitle = styled.h1`
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 20px;
  color: #1b5e20;
`;

const NoOrders = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: #388e3c;
`;

const OrdersTable = styled.div`
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  th, td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #b2dfdb;
  }

  th {
    background-color: #388e3c;
    color: white;
    font-size: 1.1rem;
    text-transform: uppercase;
  }

  td {
    background-color: #f1f8e9;
  }

  tr:nth-child(even) td {
    background-color: #e8f5e9;
  }

  tr:hover {
    background-color: #c8e6c9;
    cursor: pointer;
  }
`;

const PayButton = styled.button`
  background-color: #388e3c;
  color: white;
  padding: 8px 16px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2c6e2f;
  }

  &:focus {
    outline: none;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const ActionIcon = styled.span`
  font-size: 1.2rem;
  cursor: pointer;
  color: ${({ cancel }) => (cancel ? '#d32f2f' : '#fbc02d')};
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.2);
    color: ${({ cancel }) => (cancel ? '#c62828' : '#f9a825')};
  }
`;

const Loading = styled.div`
  text-align: center;
  font-size: 1.5rem;
  color: #1b5e20;
  margin-top: 50px;
`;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/img/logo/logo.jpeg";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
  const [scrollY, setScrollY] = useState(window.scrollY);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={setSidebarOpen} />
      {sidebarOpen && <Backdrop toggleSidebar={setSidebarOpen} />}
      <Wrapper
        className="flexCenter animate"
        style={{ height: scrollY > 100 ? "60px" : "80px" }}
      >
        <NavInner className="container flexSpaceCenter">
          {/* Logo Section */}
          <LogoLink to="/" className="pointer flexNullCenter">
            <Logo src={LogoIcon} alt="Logo" />
            <LogoText className="font20 extraBold">Elite Essays Aid</LogoText>
          </LogoLink>

          {/* Burger Icon for Mobile */}
          <BurgerWrapper onClick={() => setSidebarOpen(!sidebarOpen)}>
            <BurgerIcon />
          </BurgerWrapper>

          {/* Navigation Links */}
          <NavMenu className="flexNullCenter">
            <NavItem>
              <StyledLink to="/">Home</StyledLink>
            </NavItem>
            <NavItem>
              <StyledLink to="/about">About</StyledLink>
            </NavItem>
            <NavItem>
              <StyledLink to="/service">Services</StyledLink>
            </NavItem>
            <NavItem>
              <StyledLink to="/contact">Contact Us</StyledLink>
            </NavItem>
          </NavMenu>

          {/* Action Buttons */}
          <ActionMenu className="flexNullCenter">
            <NavItem>
              <ActionButton to="/dashboard">Dashboard</ActionButton>
            </NavItem>
            <NavItem>
              <ActionButton to="/order">Place Order</ActionButton>
            </NavItem>
            {/* <NavItem>
              <ActionButton to="/signup">Sign Up</ActionButton>
            </NavItem> */}
          </ActionMenu>
        </NavInner>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #ffffff; /* Primary background color */
  box-shadow: ${({ scrollY }) =>
    scrollY > 100 ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none"};
  transition: all 0.3s ease;
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// Logo Styles
const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const Logo = styled.img`
  width: 80px;
  height: 60px;
  margin-right: 10px;
  object-fit: cover;
`;

const LogoText = styled.h1`
  margin-left: 10px;
  font-size: 1.25rem;
  color: #025b3a; /* Consistent with navbar links */
  font-family: 'Roboto', sans-serif; /* Professional font */
`;

// Burger Icon Styles
const BurgerWrapper = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  cursor: pointer;

  @media (max-width: 760px) {
    display: block;
  }
`;

// Navigation Menu Styles
const NavMenu = styled.ul`
  display: flex;
  align-items: center;

  @media (max-width: 760px) {
    display: none; /* Hide the full navigation menu on mobile */
  }
`;

const NavItem = styled.li`
  list-style: none;
  margin: 0 10px;
`;

const StyledLink = styled(Link)`
  color: #025b3a; /* Link color */
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;

  &:hover {
    color: #025b50; /* Hover color */
  }
`;

// Action Buttons Styles
const ActionMenu = styled.ul`
  display: flex;
  align-items: center;

  @media (max-width: 760px) {
    display: none; /* Hide the action buttons on mobile */
  }
`;

const ActionButton = styled(Link)`
  padding: 8px 16px;
  border-radius: 5px;
  margin-left: 10px;
  background-color: #02505b; /* Button background color */
  color: white; /* Button text color */
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #02245b; /* Button hover color */
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/context";

const Dashboard = () => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!user) {
      console.log("User is not authenticated.");
      return;
    }
  }, [user]);

  return (
    <div>
      <h2>Dashboard</h2>
      <ul>
        {user?.orders?.map((order) => (
          <li key={order.id}>
            Order ID: {order.id} - Status: {order.status}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dashboard;

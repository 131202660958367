// src/Components/Elements/Guarantees.jsx
import React from "react";
import styled from "styled-components";
import { BiSupport, BiDollar, BiNoEntry } from "react-icons/bi"; // Import icons
// Styled components

const GuaranteeSection = styled.section`
  background-color: #fff;
  padding: 40px 0;
`;

const GuaranteeRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 20px;
`;

const GuaranteeItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333;
  gap: 10px;

  i {
    font-size: 24px;
    color: #333;
  }

  span {
    font-weight: 600;
  }
`;

// Guarantees Component
const Guarantees = () => {
  return (
    <GuaranteeSection>
   
        <GuaranteeRow>
          <GuaranteeItem>
            <BiDollar style={{ color: 'green' }} />
            <span>Money-back Guarantee</span>
          </GuaranteeItem>
          <GuaranteeItem>
            <BiSupport style={{ color: 'gray' }} />
            <span>24/7 Support Team</span>
          </GuaranteeItem>
          <GuaranteeItem>
            <BiNoEntry style={{ color: 'red' }} />
            <span>No Hidden Charges</span>
          </GuaranteeItem>
        </GuaranteeRow>
      
    </GuaranteeSection>
  );
};

export default Guarantees;

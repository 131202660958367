import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import IMG1 from "../../assets/img/images/img1.jpg";
import IM1 from "../../assets/img/add/img1.jpg";
import IMG2 from "../../assets/img/add/img2.jpg";
import IMG3 from "../../assets/img/add/img3.jpg";
import IMG4 from "../../assets/img/add/img4.jpg";




const TestimonialContainer = styled.div`
  background: 
              url(${IMG1});
  background-size: cover;
  background-position: center;
  margin-top: 10px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const SliderWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 20px;
`;

const TestimonialCard = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 15px;
  display: block;
`;

const Name = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
`;

const Title = styled.p`
  color: grey;
  margin: 5px 0 10px;
`;

const Stars = styled.div`
  margin-bottom: 15px;
`;

const Star = styled.span`
  color: #ffcc00;
  font-size: 20px;
`;

const Description = styled.p`
  font-size: 14px;
  color: #333;
`;

export default function TestimonialSlider() {
  const [testimonials, setTestimonials] = useState([
    {
      avatar: IMG3,
      name: "Paisley",
      title: "Happy Student",
      stars: 5,
      description:
        "This service exceeded my expectations! The team was professional, efficient, and always available to assist with any questions.",
    },
    {
      avatar: IMG4,
      name: "Jane ",
      title: "Happy Client",
      stars: 4,
      description:
        "I was impressed by the attention to detail and the dedication to customer satisfaction. I would definitely recommend this service!",
    },
    {
      avatar: IMG2,
      name: "Mike Researcher",
      title: "Regular Customer",
      stars: 5,
      description:
        "Amazing experience! From start to finish, everything was handled perfectly. I couldn't have asked for more.",
    },
  ]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set autoplay speed in milliseconds
    dots: false, // Hide the slide indicators
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <TestimonialContainer>
      <SliderWrapper>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index}>
              <Avatar src={testimonial.avatar} alt={testimonial.name} />
              <Name>{testimonial.name}</Name>
              <Title>{testimonial.title}</Title>
              <Stars>
                {Array.from({ length: testimonial.stars }).map((_, i) => (
                  <Star key={i}>★</Star>
                ))}
              </Stars>
              <Description>{testimonial.description}</Description>
            </TestimonialCard>
          ))}
        </Slider>
      </SliderWrapper>
    
    </TestimonialContainer>
  );
}

import React from "react";
import styled from "styled-components";
import aboutimg from "../../assets/img/images/about.jpg";
import PageHeader from "../Elements/Header";
import HeaderImage from "../../assets/img/images/img7.jpg";

const AboutSection = () => {
  return (
    <>
      <PageHeader title="About" backgroundImage={HeaderImage} height="400px" />
      <Section>
        <Container>
          <Row>
            <TextColumn>
              <Heading>About Us</Heading>
              <Description>
                Elite Essay Aid is a professional essay writing service that
                sets itself apart with a personalized approach and a commitment
                to excellence. The company is backed by a team of native
                English-speaking experts, including writers, editors, and tutors
                with strong academic backgrounds and extensive research
                experience. Elite Essays emphasizes client-focused service,
                ensuring that each customer receives individual attention and
                support. True to its name, all work at Elite Essays Aid is
                meticulously crafted by human experts, ensuring originality and
                high quality in every project, from essays to technical
                assignments.
              </Description>
              <ReadMore href="/order">Get Started</ReadMore>
            </TextColumn>
            <ImageColumn>
              <ImageWrapper>
                <Image src={aboutimg} alt="About Us" />
              </ImageWrapper>
            </ImageColumn>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default AboutSection;

// Styled Components

const Section = styled.section`
  padding: 90px 20px;
  background-color: #f9f9f9;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
  }
`;

const TextColumn = styled.div`
  flex: 1;
  min-width: 300px;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  color: #025b3a;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    width: 60px;
    height: 4px;
    background-color: #f07b26;
    position: absolute;
    left: 0;
    bottom: -10px;
    border-radius: 2px;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #333333;
  line-height: 1.6;
  margin-bottom: 25px;
`;

const ReadMore = styled.a`
  display: inline-block;
  padding: 12px 30px;
  background-color: #f07b26;
  color: #ffffff;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #bc570d;
    transform: translateY(-2px);
  }
`;

const ImageColumn = styled.div`
  flex: 1;
  min-width: 300px;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

  &:hover img {
    transform: scale(1.05);
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 50px;
    height: 60%;
    background-color: #f07b26;
    opacity: 0.2;
    transform: translateY(-50%);
    border-radius: 5px;
  }

  &::before {
    left: -25px;
    transform: translateY(-50%) rotate(15deg);
  }

  &::after {
    right: -25px;
    transform: translateY(-50%) rotate(-15deg);
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  border-radius: 15px;
`;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function PageHeader({ title, backgroundImage, height = "500px" }) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="container-fluid bg-primary py-5 mb-5 page-header"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        height: height,
        color: "#025B3A",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
      data-aos="fade-in" // Add your desired AOS animation here
    >
      <div className="container py-5" data-aos="zoom-in" data-aos-delay="200">
        <div className="row justify-content-center">
          <div className="col-lg-10 text-center">
            <div className="title" style={{ position: "relative" }}>
              <h2
                style={{
                  fontSize: "3rem", // Adjust font size
                  fontWeight: "bold", // Bold for emphasis
                  marginBottom: "20px", // Add space below the title
                  position: "relative",
                  display: "inline-block", // For underline to align with text
                }}
                data-aos="fade-up" // Animation for the title
                data-aos-delay="300"
              >
                {title}
                <span
                  style={{
                    display: "block",
                    width: "100px", // Adjust width of the underline
                    height: "4px", // Adjust height of the underline
                    backgroundColor: "#025B50", // Underline color
                    position: "absolute",
                    bottom: "-10px", // Positioning underline below the title
                    left: "22%",
                    transform: "translateX(-50%)", // Center the underline
                  }}
                  data-aos="slide-right"
                  data-aos-delay="400"
                ></span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHeader;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BsWhatsapp, BsChatDots, BsX } from "react-icons/bs";
import { FiStar } from "react-icons/fi";

// Styling for the WhatsApp Chat Button (Left Side)
const ChatButton = styled.a`
  position: fixed;
  bottom: 80px; /* Adjusted for stacking */
  right: 20px;  /* Positioned on the right */
  background-color: #25d366; /* WhatsApp green */
  color: white;
  border-radius: 30px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  text-decoration: none;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};

  &:hover {
    background-color: #1dba57;
  }

  @media (max-width: 600px) {
    bottom: 110px; /* Adjust to stack on top of the BotButton */
    right: 20px; /* Keep it fixed on the right */
    font-size: 12px;  /* Smaller font size for mobile */
    padding: 8px 16px; /* Adjust padding */
  }
`;

// Styling for the Chatbot Button (Right Side)
const BotButton = styled.div`
  position: fixed;
  bottom: 20px; /* Positioned at the bottom */
  right: 20px; /* Positioned on the right */
  background-color: #025b3a; /* Primary Chatbot Color */
  color: white;
  border-radius: 30px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};

  &:hover {
    background-color: #025b50; /* Secondary Chatbot Color */
  }

  @media (max-width: 600px) {
    bottom: 20px; /* Keep it fixed at the bottom */
    right: 20px; /* Keep it fixed on the right */
    font-size: 12px; /* Smaller font size for mobile */
    padding: 8px 16px; /* Adjust padding */
  }
`;

// Styling for the Chatbot Modal Overlay
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Styling for the Chatbot Modal Content
const ModalContent = styled.div`
  background-color: #ffffff;
  width: 90%; /* Responsive width */
  max-width: 400px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    width: 95%; /* Wider on mobile */
  }
`;

// Header of the Modal
const ModalHeader = styled.div`
  background-color: #02505b; /* Tertiary Chatbot Color */
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Body of the Modal
const ModalBody = styled.div`
  padding: 20px;
  height: 300px; /* Adjust as needed */
  overflow-y: auto;
  background-color: #f9f9f9;
`;

// Input area for user messages
const ChatInput = styled.input`
  padding: 10px;
  border: none;
  border-top: 1px solid #ddd;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
`;

// Close Button Icon
const CloseIcon = styled(BsX)`
  cursor: pointer;
  font-size: 20px;
`;

// Optional: Favorite Button (Add to Favourites)
const FavoriteButton = styled.button`
  background: none;
  border: none;
  color: #ffcc00;
  cursor: pointer;
  font-size: 20px;
  margin-left: 10px;

  &:hover {
    color: #ffaa00;
  }
`;

const ChatButtons = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([
    { sender: "bot", text: "Hello! How can I assist you today?" },
  ]);
  const [userInput, setUserInput] = useState("");
  const [showChatButtons, setShowChatButtons] = useState(true);

  // Function to toggle the chatbot modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Function to handle sending messages
  const handleSendMessage = (e) => {
    e.preventDefault();
    if (userInput.trim() === "") return;

    // Add user message
    setChatMessages([...chatMessages, { sender: "user", text: userInput }]);
    setUserInput("");

    // Simulate bot response (You can integrate with an actual chatbot service here)
    setTimeout(() => {
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Thank you for your message. We will get back to you shortly." },
      ]);
    }, 1000);
  };

  // Effect to handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.pageYOffset;
      const threshold = document.body.offsetHeight - 150; // 150px from bottom
      if (scrollPosition >= threshold) {
        setShowChatButtons(false);
      } else {
        setShowChatButtons(true);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* WhatsApp Chat Button */}
      <ChatButton
        href="https://wa.me/17722899299?text=Hello%20I%20would%20like%20to%20know%20more%20about%20your%20services"
        target="_blank"
        rel="noopener noreferrer"
        isVisible={showChatButtons}
      >
        <BsWhatsapp style={{ marginRight: "10px" }} />
        WhatsApp
      </ChatButton>

      {/* Chatbot Button */}
      <BotButton onClick={toggleModal} isVisible={showChatButtons}>
        <BsChatDots style={{ marginRight: "10px" }} />
        Chat with Us
      </BotButton>

      {/* Chatbot Modal */}
      <ModalOverlay isOpen={isModalOpen} onClick={toggleModal}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <ModalHeader>
            <h3>Chatbot</h3>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Optional: Favorite Button */}
              {/* <FavoriteButton onClick={() => alert("Added to Favourites!")}>
                <FiStar />
              </FavoriteButton> */}
              <CloseIcon onClick={toggleModal} />
            </div>
          </ModalHeader>
          <ModalBody>
            {chatMessages.map((msg, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: msg.sender === "bot" ? "flex-start" : "flex-end",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    background: msg.sender === "bot" ? "#f1f0f0" : "#025B3A",
                    color: msg.sender === "bot" ? "#000" : "#fff",
                    padding: "10px 15px",
                    borderRadius: "20px",
                    maxWidth: "80%",
                  }}
                >
                  {msg.text}
                </div>
              </div>
            ))}
          </ModalBody>
          <form onSubmit={handleSendMessage}>
            <ChatInput
              type="text"
              placeholder="Type your message..."
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
            />
          </form>
        </ModalContent>
      </ModalOverlay>
    </>
  );
};

export default ChatButtons;

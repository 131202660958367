import React, { useState, useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';  // Import useLocation
import { AuthContext } from '../context/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const LoginPage = () => {
  const [credentials, setCredentials] = useState({ usernameOrEmail: '', password: '' });
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch('https://server.eliteessayaid.com/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();
      if (data.success) {
        login(data.user); // Save user data in context
        setSuccess('Login successful! Redirecting...');
        const redirectPath = location.state?.from || '/dashboard';
        setTimeout(() => navigate(redirectPath), 2000); // Redirect after 2 seconds
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.error(error);
      setError('Something went wrong, please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header>
        <IconWrapper>
          <FontAwesomeIcon icon={faUser} />
        </IconWrapper>
        <TitleIconContainer>
          <Title>Login</Title>
        </TitleIconContainer>
      </Header>
      <Card>
        <StyledForm onSubmit={handleSubmit} noValidate>
          {error && <ErrorMessage role="alert">{error}</ErrorMessage>}
          {success && <SuccessMessage>{success}</SuccessMessage>}
          <FormGroup>
            <StyledInput
              type="text"
              name="usernameOrEmail"
              value={credentials.usernameOrEmail}
              onChange={handleChange}
              placeholder="Username or Email"
              required
              hasError={!!error}
              aria-label="Username or Email"
            />
          </FormGroup>
          <FormGroup>
            <StyledInput
              type="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              placeholder="Enter your password"
              required
              hasError={!!error}
              aria-label="Password"
            />
          </FormGroup>
          <FormGroup>
            <StyledButton type="submit" disabled={loading} aria-busy={loading}>
              {loading ? 'Logging In...' : 'Login'}
            </StyledButton>
          </FormGroup>
          <FormGroup>
            <SignUpLink>
              Don't have an account? <StyledLink to="/signup">Sign Up</StyledLink>
            </SignUpLink>
          </FormGroup>
        </StyledForm>
      </Card>
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f3f4f7;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
`;

const Header = styled.div`
  width: 40%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #025B50, #023A5B);
  color: white;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 20px;
  border-radius: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;

  @media (max-width: 600px) {
    height: 15vh;
    flex-direction: column;
    justify-content: center;
  }
`;

const TitleIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-right: 20px;
  font-weight: 700;

  @media (max-width: 600px) {
    font-size: 2rem;
    margin-bottom: 10px;
  }
`;

const IconWrapper = styled.div`
  font-size: 2rem;
  color: #ffffff;

  @media (max-width: 600px) {
    font-size: 2.5rem;
  }
`;

const Card = styled.div`
  padding: 30px;
  width: 70%;
  max-width: 400px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);

  @media (max-width: 600px) {
    padding: 20px;
    width: 90%;
  }
`;

const StyledForm = styled.form`
  width: 100%;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px 14px;
  border: 1px solid ${(props) => (props.hasError ? '#FF6B6B' : '#d3d3d3')};
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Poppins', sans-serif;

  &:focus {
    border-color: #02505B;
    box-shadow: 0 0 5px rgba(2, 91, 59, 0.5);
    outline: none;
  }

  &::placeholder {
    color: #b0b0b0;
    opacity: 1;
    font-family: 'Poppins', sans-serif;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 14px;
  background-color: #02505B;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #02245B;
    transform: scale(1.02);
  }

  &:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
    transform: none;
  }

  &:focus {
    outline: 2px solid #025B50;
    outline-offset: 2px;
  }
`;

const ErrorMessage = styled.p`
  color: #ffffff;
  background: linear-gradient(45deg, #ff6b6b, #f44336);
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const SuccessMessage = styled.p`
  color: #ffffff;
  background: linear-gradient(45deg, #4caf50, #66bb6a);
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const SignUpLink = styled.p`
  text-align: center;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
`;

const StyledLink = styled(Link)`
  color: #025B50;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
    color: #023A5B;
  }

  &:focus {
    outline: 2px solid #025B50;
    outline-offset: 2px;
  }
`;

export default LoginPage;

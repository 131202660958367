import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  margin: 0;
`;

const Card = styled.div`
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
`;

const Title = styled.h1`
  color: #28a745;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 1.5rem;
`;

const Details = styled.div`
  background-color: #f1f3f5;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;

  p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    color: #495057;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

function ConfirmationPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { orderId, amount } = location.state || {};

  return (
    <Container>
      <Card>
        <Title>Payment Successful!</Title>
        <Message>
          Thank you for your payment. Your order has been successfully processed.
        </Message>

        <Details>
          <p><strong>Order ID:</strong> {orderId}</p>
          <p><strong>Amount Paid:</strong> KES {amount}</p>
        </Details>

        <Button onClick={() => navigate("/dashboard")}>Back to Dashboard</Button>
      </Card>
    </Container>
  );
}

export default ConfirmationPage;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.min.css';

const PageContainer = styled.div`
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  padding: 40px;
  flex-direction: column;

  @media(min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    padding: 40px 60px;
  }
`;

const ToggleButton = styled.button`
  background-color: white;
  opacity:0.5;
  color: #025B3A;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #025B3A;
    color:#fff;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(108, 99, 255, 0.3);
  }

  &:active {
    background-color: #4038b7;
  }
`;

const Title = styled.h2`
  color: #6c63ff;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;

  @media(min-width: 768px) {
    text-align: left;
  }
`;

const FixedCard = styled.div`
  background-color: #025B3A;
  color: #fff;
  padding: 40px;
  border-radius: 8px;
  max-width: 350px;
  margin-bottom: 20px;

  @media(min-width: 768px) {
    margin-right: 20px;
    flex-shrink: 0; 
    margin-bottom: 0;
  }
`;

const GridContainerT = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media(min-width: 768px) {
    align-items: flex-start;
  }
`;

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  padding: 20px;
  text-align: center;
`;

const Icon = styled.div`
  font-size: 40px;
  color: #ff6b6b;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 600;
`;

const GuaranteeList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  margin: 10px 0;
`;

const ContactText = styled.p`
  margin-top: 20px;
  color: white;
  text-align: center;

  @media(min-width: 768px) {
    text-align: left;
  }
`;

const WhyChoosePage = () => {
  const [showGuarantees, setShowGuarantees] = useState(false); 
  const [isCustomerSupportAvailable, setIsCustomerSupportAvailable] = useState(false);

  const toggleGuarantees = () => {
    setShowGuarantees(!showGuarantees);
  };

  useEffect(() => {
    const fetchCustomerSupportStatus = async () => {
      setTimeout(() => {
        setIsCustomerSupportAvailable(true);
      }, 1000);
    };

    fetchCustomerSupportStatus();
  }, []);

  return (
    <PageContainer>
      <GridContainerT>
        <Title>Why Choose Elite Essays Aid For Online Assignment Help?</Title>
        <FixedCard>
          <h3>Elite Essay Aid.com Guarantees You</h3>
          <ToggleButton onClick={toggleGuarantees}>
            {showGuarantees ? "Hide" : "Show"} Guarantees
          </ToggleButton>
          {showGuarantees && (
            <GuaranteeList>
              <ListItem>1. Papers Written from Scratch</ListItem>
              <ListItem>2. Free Turnitin Reports for All Papers</ListItem>
              <ListItem>3. Anonymity as You Use Our Services</ListItem>
              <ListItem>4. Money-Back Guarantee if You Fail</ListItem>
              <ListItem>5. Customer Support Available 24/7</ListItem>
              <ListItem>6. Safety of Your Personal Details & Papers</ListItem>
              <ListItem>7. No Hidden Charges - Order Thru' Website</ListItem>
            </GuaranteeList>
          )}
          <ContactText>
            Got any questions? Feel free to contact us: <br />
            info@eliteessayaid.com
          </ContactText>
        </FixedCard>
      </GridContainerT>
      <GridContainer>
        <Card>
          <Icon>📝</Icon>
          <Text>Papers Written by Human Tutors from Scratch, no AI</Text>
        </Card>

        <Card>
          <Icon>📞</Icon>
          <Text>Customer Support Available {isCustomerSupportAvailable ? "24/7" : "Loading..."}</Text>
        </Card>

        <Card>
          <Icon>💰</Icon>
          <Text>Money-Back Guarantee if You Fail</Text>
        </Card>

        <Card>
          <Icon>🔒</Icon>
          <Text>Anonymity as You Use Our Services</Text>
        </Card>

        <Card>
          <Icon>📝</Icon>
          <Text>Free Turnitin Reports for All Papers - Plagiarism & AI Reports</Text>
        </Card>

        <Card>
          <Icon>💼</Icon>
          <Text>Free Consultation Services</Text>
        </Card>

        <Card>
          <Icon>🔒</Icon>
          <Text>Safety of Your Personal Details & Papers</Text>
        </Card>

        <Card>
          <Icon>💻</Icon>
          <Text>No Hidden Charges - Order Through the Website</Text>
        </Card>
      </GridContainer>
    </PageContainer>
  );
};

export default WhyChoosePage;

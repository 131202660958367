import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/context';
import { Link, useNavigate } from 'react-router-dom';
import Myorders from "./Myorders";

const Dashboard = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login'); // Redirect to the login page after logout
  };

  const toggleProfileMenu = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };

  return (
    <div style={styles.container}>
      {/* Header */}
      <header style={styles.header}>
        <div style={styles.logo}>
          <h1 style={styles.logoText}>My Dashboard</h1>
        </div>
        <nav style={styles.nav}>
          <Link to="/" style={styles.navLink}>
            Home
          </Link>
          <Link to="/order" style={styles.navLink}>
            Orders
          </Link>
          {/* <Link to="/profile" style={styles.navLink}>
            Profile
          </Link> */}
        </nav>
        <div style={styles.profileContainer}>
          <button onClick={toggleProfileMenu} style={styles.profileButton}>
            {user?.username || 'Guest'}
          </button>
          {profileMenuOpen && (
            <div style={styles.profileMenu}>
              <Link to="/profile" style={styles.profileMenuItem}>
                View Profile
              </Link>
              <button onClick={handleLogout} style={styles.logoutButton}>
                Logout
              </button>
            </div>
          )}
        </div>
      </header>

      {/* Main Container */}
      <div style={styles.contentContainer}>
        <h2>Welcome, {user?.username || 'Guest'}!</h2>
        <Myorders />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh', // Ensures it spans the full viewport height
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#F5F5F5',
    color: '#333',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#025B3A',
    color: '#FFF',
    padding: '10px 20px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  contentContainer: {
    flex: 1, // Allows the container to grow and push the footer down
    padding: '20px',
    backgroundColor: '#FFF',
    margin: '20px',
    borderRadius: '5px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    overflow: 'auto', // Ensures scrolling if content is too large
  },
  logo: {
    fontSize: '1.5em',
  },
  logoText: {
    color: '#FFF',
    fontWeight: 'bold',
  },
  nav: {
    display: 'flex',
    gap: '20px',
  },
  navLink: {
    color: '#FFF',
    textDecoration: 'none',
    fontWeight: 'bold',
    padding: '8px 15px',
    borderRadius: '5px',
    backgroundColor: '#023A5B',
    transition: 'background-color 0.3s',
  },
  profileContainer: {
    position: 'relative',
  },
  profileButton: {
    backgroundColor: '#02245B',
    color: '#FFF',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 15px',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  profileMenu: {
    position: 'absolute',
    top: '100%',
    right: 0,
    backgroundColor: '#FFF',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    overflow: 'hidden',
    marginTop: '10px',
    zIndex: 10,
  },
  profileMenuItem: {
    display: 'block',
    padding: '10px 15px',
    color: '#333',
    textDecoration: 'none',
    backgroundColor: '#FFF',
    borderBottom: '1px solid #EEE',
    transition: 'background-color 0.3s',
  },
  logoutButton: {
    display: 'block',
    padding: '10px 15px',
    width: '100%',
    border: 'none',
    backgroundColor: '#FF5757',
    color: '#FFF',
    fontWeight: 'bold',
    textAlign: 'left',
    cursor: 'pointer',
  },
};

export default Dashboard;

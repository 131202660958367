// frontend/src/App.js
import React from 'react';
import { AuthProvider } from './Components/context/context'; // Ensure correct path to context
import Landing from './Components/screens/Landing'; // Ensure correct path to Landing component

const App = () => {
  return (
    <AuthProvider>
      <Landing />
    </AuthProvider>
  );
};

export default App;

import React, { useState, useContext } from "react";
import styled from "styled-components";
import { FaGraduationCap, FaFileAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import AcademicLevelSelector from "../Elements/Academic";
import PaperDetailsForm from "../Elements/PaperDetail";
import PriceCalculatorCard from "../Elements/PricingCalculator";
import { AuthContext } from "../context/context"; // Ensure correct path to AuthContext

const OrderPage = () => {
  const [orderData, setOrderData] = useState({
    academicLevel: 'Undergraduate',
    typeOfPaper: 'Essay',
    topic: 'Artificial Intelligence in Healthcare',
    instructions: 'Discuss how AI is transforming the healthcare industry with examples.',
    writerCategory: 'Expert',
    getWriterSamples: true,
    expertProofreading: false,
    vipSupport: true,
    getDraftOutline: true,
    totalPrice: 49.99,
  });

  const { user } = useContext(AuthContext); // Assuming user is logged in
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setOrderData({
      ...orderData,
      [name]: type === 'checkbox' ?  e.target.checked : e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!orderData.academicLevel || !orderData.typeOfPaper || !orderData.topic || !orderData.instructions) {
      setError('Please fill in all the required fields.');
      return;
    }

    if (!user) {
      setError('You must be logged in to place an order.');
      return;
    }

    const orderPayload = {
      userId: user.id, // Assuming 'user' has an 'id' field
      academicLevel: orderData.academicLevel,
      typeOfPaper: orderData.typeOfPaper,
      topic: orderData.topic,
      instructions: orderData.instructions,
      writerCategory: orderData.writerCategory,
      getWriterSamples: orderData.getWriterSamples ? 1 : 0,
      expertProofreading: orderData.expertProofreading ? 1 : 0,
      vipSupport: orderData.vipSupport ? 1 : 0,
      getDraftOutline: orderData.getDraftOutline ? 1 : 0,
      totalPrice: orderData.totalPrice,
    };
    setLoading(true);
    try {
      const response = await fetch('https://server.eliteessayaid.com/orders.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderPayload),
      });

      const data = await response.json();
      if (data.success) {
        setSuccess('Order placed successfully!');
        navigate('/dashboard'); // Redirect to dashboard on successful order
      } else {
        setError('Order submission failed. Please try again.');
      }
    } catch (error) {
      console.error("Error:", error);
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <OrderRow>
        <LeftColumn>
          <SectionBanner>
            <FaGraduationCap size={24} />
            <BannerText>1. Academic Level</BannerText>
          </SectionBanner>
          <ContentCard>
            <AcademicLevelSelector formData={orderData} setFormData={setOrderData} />
          </ContentCard>

          <SectionBanner>
            <FaFileAlt size={24} />
            <BannerText>2. Paper Details</BannerText>
          </SectionBanner>
          <ContentCard>
            <form onSubmit={handleSubmit}>
              <PaperDetailsForm formData={orderData} handleChange={handleChange} />

              <WriterCategoryCard>
                <h6>Writer Category</h6>
                <ButtonGroup>
                  <WriterButton
                    active={orderData.writerCategory === "Best available"}
                    onClick={() => setOrderData({ ...orderData, writerCategory: "Best available" })}
                  >
                    Best available <br />
                    <small>Standard price</small>
                  </WriterButton>
                  <WriterButton
                    active={orderData.writerCategory === "Advanced"}
                    onClick={() => setOrderData({ ...orderData, writerCategory: "Advanced" })}
                  >
                    Advanced <br />
                    <small>+25%</small>
                  </WriterButton>
                  <WriterButton
                    active={orderData.writerCategory === "ENL"}
                    onClick={() => setOrderData({ ...orderData, writerCategory: "ENL" })}
                  >
                    ENL <br />
                    <small>+30%</small>
                  </WriterButton>
                </ButtonGroup>
              </WriterCategoryCard>

              <AdditionalServicesCard>
                <h6>Additional Services</h6>
                <CheckboxContainer>
                  <label>
                    <input
                      type="checkbox"
                      name="getWriterSamples"
                      checked={!!orderData.getWriterSamples}
                      onChange={handleChange}
                    />
                    Get Writer Samples ($5.00)
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="expertProofreading"
                      checked={!!orderData.expertProofreading}
                      onChange={handleChange}
                    />
                    Expert Proofreading ($6.00)
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="vipSupport"
                      checked={!!orderData.vipSupport}
                      onChange={handleChange}
                    />
                    VIP Support ($9.99)
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="getDraftOutline"
                      checked={!!orderData.getDraftOutline}
                      onChange={handleChange}
                    />
                    Get Draft/Outline ($6.00)
                  </label>
                </CheckboxContainer>
              </AdditionalServicesCard>

              {error && <ErrorMessage>{error}</ErrorMessage>}
              {success && <SuccessMessage>{success}</SuccessMessage>}

              <SubmitButton type="submit" disabled={loading}>
                {loading ? "Submitting..." : `Safe checkout - $${orderData.totalPrice.toFixed(2)}`}
              </SubmitButton>
            </form>
          </ContentCard>
        </LeftColumn>

        <RightColumn>
          <StickyCard>
            <PriceCalculatorCard formData={orderData} />
          </StickyCard>
        </RightColumn>
      </OrderRow>
    </Container>
  );
};

export default OrderPage;

// Styled components

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 14px;
  margin-top: 10px;
`;

// Other styled components remain the same as in the original code.

const Container = styled.div`
  padding: 50px 15px;
  background-color: #f2f8f8;
  max-width: 1200px;
  margin: 0 auto;
`;

const OrderRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  flex: 1 1 65%;
  padding-right: 20px;
  min-width: 300px;

  @media (max-width: 768px) {
    padding-right: 0;
    width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 1 1 30%;
  min-width: 300px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ContentCard = styled.div`
  background: white;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-radius: 8px;
`;

const SectionBanner = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const BannerText = styled.h4`
  margin-left: 8px;
  color: #333;
`;

const WriterCategoryCard = styled.div`
  margin-top: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const WriterButton = styled.button`
  flex: 1;
  background-color: ${({ active }) => (active ? "#4CAF50" : "#f1f1f1")};
  color: ${({ active }) => (active ? "white" : "black")};
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ddd;
  }
`;

const AdditionalServicesCard = styled.div`
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const SubmitButton = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #45a049;
  }
`;

const StickyCard = styled.div`
  position: sticky;
  top: 20px;
  align-self: flex-start; /* Aligns to the start of the flex container */
`;

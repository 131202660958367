import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons"; // Correctly import Twitter/X icon

// Styled Components
const FooterWrapper = styled.footer`
  color: #444444;
  font-size: 14px;
  background: #f1f7fd;
`;

const FooterTop = styled.div`
  padding: 60px 0 30px 0;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
`;

const FooterContact = styled.div`
  margin-bottom: 30px;

  h3 {
    font-size: 22px;
    margin: 0 0 30px 0;
    padding: 2px 0;
    line-height: 1;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #777777;
  }
`;

const FooterLinks = styled.div`
  margin-bottom: 30px;

  h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    position: relative;
    padding-bottom: 12px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ul i {
    padding-right: 2px;
    color: #025b3a; /* Adjust color as needed */
    font-size: 18px;
    line-height: 1;
  }

  ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }

  ul a {
    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }

  ul a:hover {
    text-decoration: none;
    color: #c1272d;
  }
`;

const FooterNewsletter = styled.div`
  font-size: 15px;

  h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    position: relative;
    padding-bottom: 12px;
  }

  form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    text-align: left;
    border: 1px solid #bfdcf7;

    input[type="email"] {
      border: 0;
      padding: 4px 8px;
      width: calc(100% - 100px);
    }

    input[type="submit"] {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border: 0;
      background: none;
      font-size: 16px;
      padding: 0 20px;
      background: #025b50; /* Adjust color as needed */
      color: #fff;
      transition: 0.3s;
      border-radius: 50px;
    }

    input[type="submit"]:hover {
      background: #025b50; /* Adjust color as needed */
    }
  }
`;

const Credits = styled.div`
  padding-top: 5px;
  font-size: 13px;
  color: #444444;
`;

const SocialLinks = styled.div`
  a {
    font-size: 18px;
    display: inline-block;
    background: #02505b; /* Adjust color as needed */
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;

    &:hover {
      background: #023a5b; /* Adjust color as needed */
      color: #fff;
      text-decoration: none;
    }
  }
`;

// Footer Component
function Footer() {
  return (
    <FooterWrapper>
      <FooterTop>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <FooterContact>
                <h3>Elite Essay Aid</h3>
                <p>
                  <strong>Phone:</strong> +1(17722899299)
                  <br />
                  <strong>Email:</strong> info@eliteaidessay.com
                  <br />
                </p>
              </FooterContact>
            </div>
            <div className="col-lg-2 col-md-6">
              <FooterLinks>
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="#">Home</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#">About us</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#">Services</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#">Terms of service</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#">Privacy policy</a>
                  </li>
                </ul>
              </FooterLinks>
            </div>
            <div className="col-lg-3 col-md-6">
              <FooterLinks>
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#">Assignments</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#">Thesis</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#">Dissertation</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#">Research Paper</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#">Essay</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#">Coursework</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#">Data Analysis & Statistics</a>
                  </li>
                </ul>
              </FooterLinks>
            </div>
            <div className="col-lg-4 col-md-6">
              <FooterNewsletter>
                <h4>Join Our Newsletter</h4>
                <form action="" method="post">
                  <input type="email" name="email" />
                  <input type="submit" value="Subscribe" />
                </form>
              </FooterNewsletter>
            </div>
          </div>
        </div>
      </FooterTop>
      <div className="container d-md-flex py-4">
        <div className="me-md-auto text-center text-md-start">
          <Credits>
            &copy; Copyright{" "}
            <strong>
              <span>Elite Essay Aid</span>
            </strong>
            . All Rights Reserved
          </Credits>
        </div>
        <SocialLinks>
          <a
            href="https://x.com/Elite_Essay_Aid?t=DBRSle0-n7RNXaBWxwMAUQ&s=09"
            className="twitter"
          >
            <FontAwesomeIcon icon={faXTwitter} />
          </a>

          <a
            href="https://www.facebook.com/profile.php?id=61566787580146"
            className="facebook"
          >
            <i className="bx bxl-facebook"></i>
          </a>
          <a href="#" className="instagram">
            <i className="bx bxl-instagram"></i>
          </a>
          <a href="#" className="google-plus">
            <i className="bx bxl-skype"></i>
          </a>
          <a href="#" className="linkedin">
            <i className="bx bxl-linkedin"></i>
          </a>
        </SocialLinks>
      </div>
    </FooterWrapper>
  );
}

export default Footer;

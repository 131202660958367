import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/context";
import "intasend-inlinejs-sdk";

function MyScreen() {
  const { user, updateUserData } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { orderId, amount: initialAmount } = location.state || {};
  const [buttonText, setButtonText] = useState("Pay Now");
  const [isDisabled, setIsDisabled] = useState(false);
  const [amount, setAmount] = useState(initialAmount || 10);
  const [paymentStatus, setPaymentStatus] = useState("");
  const isMounted = React.useRef(true);

  useEffect(() => {
    const intasend = new window.IntaSend({
      publicAPIKey: "ISPubKey_test_86016616-fd88-4980-bfbc-9baf3d3997a1",
      live: false,
    });

    const handlePaymentComplete = async (response) => {
      if (!isMounted.current) return;
      console.log("Payment COMPLETE:", response);
      setButtonText("Paid");
      setIsDisabled(true);
      setPaymentStatus("success");
      alert(`Payment successful for Order ID: ${orderId}`);

      try {
        const updateResponse = await fetch(
          "https://server.eliteessayaid.com/update_order_status.php",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              orderId,
              status: "Paid",
            }),
          }
        );

        if (!updateResponse.ok) {
          throw new Error(`HTTP error! Status: ${updateResponse.status}`);
        }

        const updateData = await updateResponse.json();
        console.log("Response from server:", updateData);

        if (updateData.success) {
          alert("Order status updated to Paid.");
          const updatedOrders = user.orders.map((order) =>
            order.id === orderId ? { ...order, status: "Paid" } : order
          );
          updateUserData({ ...user, orders: updatedOrders });
        } else {
          alert("Failed to update order status on the server.");
        }
      } catch (error) {
        console.error("Error updating order status:", error);
      }

      if (orderId) navigate("/confirmation", { state: { orderId, amount } });
    };

    const handlePaymentFailed = (response) => {
      if (!isMounted.current) return;
      console.error("Payment FAILED:", response);
      setPaymentStatus("failed");
      alert("Payment failed. Please try again.");
    };

    const handlePaymentInProgress = () => {
      if (!isMounted.current) return;
      console.log("Payment IN PROGRESS...");
      setPaymentStatus("in-progress");
    };

    intasend.on("COMPLETE", handlePaymentComplete);
    intasend.on("FAILED", handlePaymentFailed);
    intasend.on("IN-PROGRESS", handlePaymentInProgress);

    return () => {
      isMounted.current = false;
    };
  }, [orderId, user, updateUserData, navigate, amount]);

  const isAmountValid = amount > 0;
  const isProcessing = paymentStatus === "in-progress";

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRadius: "10px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      maxWidth: "400px",
      margin: "50px auto",
      fontFamily: "Arial, sans-serif",
    },
    heading: {
      fontSize: "1.5rem",
      marginBottom: "20px",
      color: "#333",
    },
    label: {
      fontSize: "1rem",
      marginBottom: "10px",
      color: "#555",
      display: "block",
    },
    input: {
      width: "100%",
      padding: "10px",
      margin: "10px 0",
      border: "1px solid #ddd",
      borderRadius: "5px",
      fontSize: "1rem",
    },
    button: {
      backgroundColor: "#ff5757",
      color: "#fff",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: isDisabled || isProcessing ? "not-allowed" : "pointer",
      fontSize: "1rem",
      transition: "background-color 0.3s ease",
      marginTop: "10px",
    },
    buttonDisabled: {
      backgroundColor: "#ccc",
    },
    message: {
      marginTop: "15px",
      fontSize: "1rem",
      color: paymentStatus === "success" ? "green" : paymentStatus === "failed" ? "red" : "#333",
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Payment for Order ID: {orderId}</h2>
      {paymentStatus && <p style={styles.message}>{
        paymentStatus === "in-progress"
          ? "Payment is in progress..."
          : paymentStatus === "success"
          ? "Payment successful! Processing your order."
          : "Payment failed. Please try again."
      }</p>}
      <label style={styles.label}>
        Enter Amount:
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
          min="1"
          disabled={isProcessing || isDisabled}
          style={styles.input}
        />
      </label>
      <button
        style={{
          ...styles.button,
          ...(isDisabled || !isAmountValid || isProcessing ? styles.buttonDisabled : {}),
        }}
        className="intaSendPayButton"
        data-amount={amount}
        data-currency="KES"
        disabled={isDisabled || !isAmountValid || isProcessing}
      >
        {buttonText}
      </button>
    </div>
  );
}

export default MyScreen;

import React, { useState } from "react";
import styled, { css } from "styled-components";
import CountUp from "react-countup";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "boxicons/css/boxicons.min.css"; // Import Boxicons

// Color palette
const colors = {
  background: "#fff", // Background for the section
  primaryText: "#025B3A", // Primary text (titles)
  secondaryText: "#02505B", // Descriptions and secondary text
  iconColor: "#025B50", // Icons color
  hoverAccent: "#02245B", // Hover state or accent
  white: "#ffffff", // White for contrast
  borderColor: "#e0e0e0", // Border color for cards
  activeBorderColor: "#025b50", // Border color for active card
  activeShadow: "0px 15px 25px rgba(0, 0, 0, 0.2)", // Shadow for active card
};

// Container for the entire section
const CounterSection = styled.section`
  background-color: ${colors.background};
  padding: 60px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
`;

// Wrapper to center the content and control the width
const CounterWrapper = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`;

// Each achievement or service item
const ServiceItem = styled.div`
  text-align: center;
  color: ${colors.primaryText};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px; /* Increased margin for larger gap */
  height: 100%;
  background-color: ${colors.white};
  border-radius: 12px;
  padding: 25px; /* Increased padding for better spacing */
  border: 1px solid ${colors.borderColor}; /* Added border */
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;

  /* Conditional styling for active card */
  ${({ isActive }) =>
    isActive &&
    css`
      transform: translateY(-10px);
      box-shadow: ${colors.activeShadow};
      border-color: ${colors.activeBorderColor};
    `}
`;

// Icon styling for Boxicons
const ServiceIcon = styled.i`
  font-size: 50px; /* Increased icon size for better visibility */
  color: ${colors.iconColor};
  margin-bottom: 20px; /* Increased margin for spacing */
  background: linear-gradient(
    135deg,
    ${colors.primaryText},
    ${colors.hoverAccent}
  );
  -webkit-background-clip: text;
  color: transparent;

  transition: all 0.3s ease;

  &:hover {
    color: ${colors.hoverAccent}; /* Hover effect for icons */
  }
`;

// Service title or description text
const ServiceTitle = styled.h3`
  font-size: 22px; /* Increased font size for titles */
  color: ${colors.primaryText};
  font-weight: 600;
  margin-bottom: 15px; /* Increased margin for spacing */
  position: relative;
`;

// Service description text
const ServiceDescription = styled.p`
  font-size: 16px;
  color: ${colors.secondaryText};
  line-height: 1.6; /* Improved readability */
`;

// Styled Slider component to target active slide
const StyledSlider = styled(Slider)`
  .slick-slide {
    padding: 10px;
    transition: all 0.3s ease;
  }

  .slick-center .service-item {
    transform: translateY(-10px);
    box-shadow: ${colors.activeShadow};
    border-color: ${colors.activeBorderColor};
  }

  .slick-slide > div {
    display: flex;
    justify-content: center;
  }
`;

export default function CounterSectionComponent() {
  const [activeSlide, setActiveSlide] = useState(0);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 3, // Show 3 slides on large screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500, // Reduced autoplay speed for smoother transitions
    centerMode: true, // Enables center mode
    centerPadding: "0px", // Removes padding on the sides
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Maintain 3 slides on medium screens
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Show 1 slide on small screens
          centerMode: false, // Disable center mode on small screens
        },
      },
    ],
  };

  const services = [
    {
      icon: "bx bx-pencil",
      count: 50000,
      label: "Papers Written",
      description: "Trusted by students globally",
    },
    {
      icon: "bx bx-book",
      count: 100,
      label: "Subjects Covered",
      description: "From literature to engineering",
    },
    {
      icon: "bx bx-smile",
      count: 98,
      label: "Customer Satisfaction",
      description: "High-quality papers guaranteed",
    },
    {
      icon: "bx bx-shield",
      count: 100,
      label: "Plagiarism-Free",
      description: "Original content every time",
    },
    {
      icon: "bx bx-user",
      count: 500,
      label: "Experienced Writers",
      description: "Experts in every field",
    },
  ];

  return (
    <CounterSection>
      <CounterWrapper>
        <StyledSlider {...sliderSettings}>
          {services.map((service, index) => (
            <ServiceItem
              key={index}
              isActive={activeSlide === index}
              className="service-item"
            >
              <ServiceIcon
                className={service.icon}
                aria-label={`${service.label} Icon`}
              />{" "}
              {/* Boxicon Icon */}
              <ServiceTitle>
                <CountUp end={service.count} duration={10.5} />
                {service.label.includes("%") ? "%" : "+"} {service.label}
              </ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
            </ServiceItem>
          ))}
        </StyledSlider>
      </CounterWrapper>
    </CounterSection>
  );
}

// Additional Global Styles (if not using a separate CSS file)
// Ensure 'Roboto' font is imported in your project, typically in index.html or via CSS-in-JS

/*
body {
  font-family: 'Roboto', sans-serif;
  color: #0c0c0c;
  background-color: #ffffff;
  overflow-x: hidden;
}
*/

import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Container, Card, CardBody, Form, FormGroup, Input, Button, Alert } from 'reactstrap';
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from 'react-icons/ai';


// Styled components
const StyledContainer = styled(Container)`
  background-color: #f8f9fa;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
`;

const StyledCard = styled(Card)`
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  padding: 20px;
`;

const StyledInput = styled(Input)`
  border-radius: 8px;
  margin-bottom: 15px;
  &:focus {
    border-color: #02505B;
    box-shadow: 0 0 5px rgba(2, 91, 59, 0.5);
  }
`;

const StyledButton = styled(Button)`
  background-color: #02505B;
  width: 100%;
  &:hover {
    background-color: #02245B;
  }
`;

const FeedbackBanner = styled(Alert)`
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 15px;
  font-size: 0.95rem;
  font-weight: 500;
  margin-bottom: 20px;

  svg {
    margin-right: 10px;
  }
`;

const Signup = () => {
  const [formData, setFormData] = useState({
    username: '',
    name: '',
    email: '',
    phone_number: '',
    password: '',
    confirmPassword: '',
  });

  const [feedback, setFeedback] = useState({
    type: '', // 'error' or 'success'
    message: '',
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setFeedback({ type: 'error', message: 'Passwords do not match.' });
      return;
    }

    setFeedback({ type: '', message: '' });
    setLoading(true);

    try {
      const res = await axios.post('https://server.eliteessayaid.com/register.php', {
        username: formData.username,
        name: formData.name,
        email: formData.email,
        phone_number: formData.phone_number,
        password: formData.password,
      });

      if (res.data.success) {
        setFeedback({ type: 'success', message: res.data.message });
        setFormData({
          username: '',
          name: '',
          email: '',
          phone_number: '',
          password: '',
          confirmPassword: '',
        });
      } else {
        setFeedback({ type: 'error', message: res.data.message });
      }
    } catch (err) {
      setFeedback({
        type: 'error',
        message: 'An unexpected error occurred. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledContainer>
      <StyledCard>
        <CardBody>
          {feedback.message && (
            <FeedbackBanner color={feedback.type === 'success' ? 'success' : 'danger'}>
             {feedback.type === 'success' ? <AiOutlineCheckCircle size={20} /> : <AiOutlineExclamationCircle size={20} />}

              {feedback.message}
            </FeedbackBanner>
          )}
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <StyledInput
                type="text"
                name="username"
                placeholder="Username"
                value={formData.username}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <StyledInput
                type="text"
                name="name"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <StyledInput
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <StyledInput
                type="text"
                name="phone_number"
                placeholder="Phone Number"
                value={formData.phone_number}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <StyledInput
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <StyledInput
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <StyledButton type="submit" disabled={loading}>
              {loading ? 'Signing Up...' : 'Sign Up'}
            </StyledButton>
          </Form>
        </CardBody>
      </StyledCard>
    </StyledContainer>
  );
};

export default Signup;

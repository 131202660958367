// src/PrivateRoutes.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './context/context'; // Ensure correct path

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);

  // If user is authenticated, render the children (protected component)
  // Otherwise, redirect to the login page
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BiClipboard, BiTask, BiCheckCircle } from 'react-icons/bi'; // Importing icons from Boxicons
// Removed FullButton import as we're creating a custom Button

// Styled Components
const Section = styled.section`
  padding: 60px 0;
  text-align: center;
  background-color: #f0f4f8;
`;

const Container = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: bold;
  color: #4d2062; /* Adjust as per design */
  margin-bottom: 40px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Col = styled.div`
  flex: 0 0 30%;
  max-width: 30%;
  margin-bottom: 40px;
  text-align: center;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const IconWrapper1 = styled.div`
  margin-bottom: 20px;

  svg {
    width: 100px;
    height: 100px;
    color: #e0e0e0;
    background-color: #e6f7ff; /* Removed the extra closing parenthesis */
    padding: 10px;
    border-radius: 50%;
  }
`;

const IconWrapper2 = styled.div`
  margin-bottom: 20px;

  svg {
    width: 100px;
    height: 100px;
    color: #faad14;
    background-color: #e6f7ff;
    padding: 10px;
    border-radius: 50%;
  }
`;

const IconWrapper3 = styled.div`
  margin-bottom: 20px;

  svg {
    width: 100px;
    height: 100px;
    color: #52c41a;
    background-color: #e6f7ff;
    padding: 10px;
    border-radius: 50%;
  }
`;

const StepTitle = styled.h3`
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
`;

const StepDescription = styled.p`
  font-size: 16px;
  color: #777;
  line-height: 1.6;

  a {
    color: #4d2062; /* Optional: Styling for the link */
    text-decoration: underline;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  /* Removed left: 50% and width: 20% to allow flex to handle centering */
`;

const Button = styled.button`
  background-color: white;
  opacity: 0.8; /* Adjusted for better visibility */
  color: #025b3a;
  padding: 12px 30px;
  border: 2px solid #025b3a;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, opacity 0.3s ease;

  &:hover {
    background-color: #025b3a;
    color: white;
    opacity: 1;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(2, 91, 58, 0.5);
  }
`;

const HowItWorksSection = () => {
  return (
    <Section>
      <Container>
        <Title>How It Works - Just 3 Steps!</Title>
        <Row>
          <Col>
            <IconWrapper1>
              <BiClipboard />
            </IconWrapper1>
            <StepTitle>Fill in a Brief</StepTitle>
            <StepDescription>
              Brief the team by filling in <a href="/order">this order form</a> – tell us the number of pages, deadline, attach any relevant files, then check out.
            </StepDescription>
          </Col>
          <Col>
            <IconWrapper2>
              <BiTask />
            </IconWrapper2>
            <StepTitle>Order in Progress</StepTitle>
            <StepDescription>
              Our team works on your order and keeps in touch with you via WhatsApp, Email, or your preferred communication channel.
            </StepDescription>
          </Col>
          <Col>
            <IconWrapper3>
              <BiCheckCircle />
            </IconWrapper3>
            <StepTitle>Get it done in Time</StepTitle>
            <StepDescription>
              Once done, we will upload the assignment plus Plagiarism and AI reports on the website for you to download & review.
            </StepDescription>
          </Col>
        </Row>
        <BtnWrapper data-aos="fade-up" data-aos-delay="400">
        <Link to="/order">
    <Button>Get IT DONE</Button>
  </Link>
        </BtnWrapper>
      </Container>
    </Section>
  );
};

export default HowItWorksSection;

import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenAlt, faEdit, faGraduationCap, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Image1 from '../../assets/img/hand-drawn-essay-illustration_23-2150268421.jpg';
import IMG1 from "../../assets/img/images/img3.webp";

// Styled Components
const Section = styled.section`
  padding: 80px 0;
  background: #f9f9f9;
  position: relative;
`;

const Container = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Column = styled.div`
  flex: 1;
  padding: 15px;
  max-width: 33.3333%;
  box-sizing: border-box;
  text-align: center;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ReadMoreButton = styled.button`
  background-color: #025B3A;
  color: white;
  padding: 12px 18px;
  border: none;
  border-radius: 25px;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 15px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 15px);
  bottom: 20px;

  &:hover {
    background-color: #02245B;
    transform: translate(-50%, 0);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(108, 99, 255, 0.3);
  }

  &:active {
    background-color: #4038b7;
  }
`;

const ServiceItem = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  &:hover ${ReadMoreButton} {
    opacity: 1;
    visibility: visible;
  }
`;

const ServiceIcon = styled.div`
  font-size: 48px;
  color: #025B3A;
  margin-bottom: 20px;
  transition: color 0.3s ease;

  &:hover {
    color: #02245B;
  }
`;

const ServiceTitle = styled.h3`
  font-size: 22px;
  margin-top: 20px;
  color: #023A5B;
`;

const ServiceDescription = styled.p`
  color: #777;
  margin-top: 10px;
  line-height: 1.6;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f4f4f9;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 15px;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  padding-left: 20px;

  h4 {
    font-size: 26px;
    color: #02505B;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }

  @media (max-width: 768px) {
    padding: 0;
    h4 {
      font-size: 22px;
    }
    p {
      font-size: 14px;
    }
  }
`;

const StyledImage = styled.img`
  width: 180px;
  height: auto;
  flex-shrink: 0;
  border-radius: 10%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 120px;
  }
`;

const FeaturesSection = () => {
  return (
    <Section>
      <Container>
        <Row>
          <SectionTitle>
            <StyledImage src={IMG1} alt="Illustration representing academic writing services" />
            <TextWrapper>
              <h4>We Are Dedicated to Enhancing Your Academic Writing</h4>
              <p>
                Our team of professional writers and editors is committed to providing 
                high-quality academic writing assistance to students and researchers. 
                We offer services that are designed to improve clarity, coherence, 
                and academic rigor in your work.
              </p>
            </TextWrapper>
          </SectionTitle>
        </Row>
        <Row>
          <Column>
            <ServiceCard 
              icon={faPenAlt} 
              title="Custom Essay Writing" 
              description="Our experienced writers craft custom essays that meet academic standards, tailored to your specific requirements and guidelines."
              moreDescription="In addition to custom writing, we provide topic selection assistance and personalized consultations to ensure your essay aligns perfectly with your academic goals."
            />
          </Column>
          <Column>
            <ServiceCard 
              icon={faEdit} 
              title="Editing & Proofreading" 
              description="We offer meticulous editing and proofreading services to ensure your academic writing is free from errors and adheres to the highest standards of grammar and style."
              moreDescription="Our editors focus on enhancing the structure, flow, and overall quality of your work, providing comprehensive feedback to elevate your academic papers."
            />
          </Column>
          <Column>
            <ServiceCard 
              icon={faGraduationCap} 
              title="Research Paper Assistance" 
              description="Whether you need help with research, writing, or formatting, we provide comprehensive support for crafting well-researched and impactful papers."
              moreDescription="Our experts assist with data analysis, literature reviews, and citation management, ensuring your research papers are thorough, well-structured, and professionally presented."
            />
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

// Service Card Component
const ServiceCard = ({ icon, title, description, moreDescription }) => {
  const [showReadMore, setShowReadMore] = useState(false);

  const toggleReadMore = () => {
    setShowReadMore(!showReadMore);
  };

  return (
    <ServiceItem>
      <ServiceIcon>
        <FontAwesomeIcon icon={icon} aria-label={`${title} Icon`} />
      </ServiceIcon>
      <ServiceTitle>{title}</ServiceTitle>
      <ServiceDescription>
        {description}
        {showReadMore && <span> {moreDescription}</span>}
      </ServiceDescription>
      <ReadMoreButton 
        onClick={toggleReadMore} 
        aria-label={showReadMore ? "Hide details" : "Show more details"}
      >
        {showReadMore ? "Hide" : "Read More"} <FontAwesomeIcon icon={showReadMore ? faChevronLeft : faChevronRight} />
      </ReadMoreButton>
    </ServiceItem>
  );
};

export default FeaturesSection;
